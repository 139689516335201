import { catchError, map, mergeMap } from 'rxjs/operators';
import { Asset } from '../../../shared/interfaces/asset';
import { Component, Output, Input, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { throwError, forkJoin, of, Subscription, Observable } from 'rxjs';
import { AssetType } from '../../../shared/interfaces/asset-type';
import { AssetModel } from '../../../shared/interfaces/asset-model';
import { DatePipe, Location } from '@angular/common';
import { AdminService } from '../../../shared/services/admin.service';
import { AdminScoutInstallation } from '../../../shared/interfaces/admin/admin-scout-installation';
import { ScoutContent } from '../../../shared/interfaces/scout-content';
import { AssetsService } from '../../../shared/services/assets.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialogComponent } from '../../../shared/components/dialogs/confirmation-dialog/confirmation-dialog.component';
import { AdminScout } from '../../../shared/interfaces/admin/admin-scout';
import { ConfirmAssetModelDialogComponent } from './dialog/confirm-asset-model-dialog.component';
import { FormControl } from '@angular/forms';
import { AssetStatus } from '../../../shared';
import { Notes } from '../../../shared/interfaces/notes';
import { AssetAppModel } from '../../../shared/interfaces/asset-app-model';

@Component({
  selector: 'app-asset-edit',
  templateUrl: './asset-edit.component.html',
  styleUrls: ['./asset-edit.component.scss']
})
export class AssetEditComponent implements OnInit {
  @Output() selectEmitt = new EventEmitter();
  @Input() asset: Asset;
  assetInitState: Asset;
  assets?: Asset[];
  assetModel: AssetAppModel[];
  assetStatus: AssetStatus[];
  model: AssetModel;
  assetType: AssetType;
  event: EventEmitter<any> = new EventEmitter();
  assetId = '';
  scoutId = '';
  updateScout: AdminScout;
  initScoutName = '';
  scout: ScoutContent;
  installs: AdminScoutInstallation[] = [];
  initInstalls: AdminScoutInstallation[] = [];
  formattedInstalls: any[] = [];
  ownershipHistory: any[] = [];
  index = 0;
  selectedMonth = 'All';
  selectedBeginMonth = 'All';
  selectedEndMonth = '';
  selectedYear;
  yearMin = 2010;
  year = new Date().getFullYear();
  years: number[] = [];
  // years are being populated correctly
  val = new Date(this.year, 6, 13).toString();
  intervalChanged = false;
  _interval: number;
  loading = true;
  firmwares: any[] = [];
  coordinates: any[] = [];
  scouts: AdminScout[] = [];
  notes: Notes[] = [];
  title = '';
  toolTip = '';
  _MODEL_CHANGE = false;
  _STATUS_CHANGE = false;
  connectivityCodeChange = false;
  connectivityCodeFormControl = new FormControl({ value: null, disabled: true });
  assetSubscription: Subscription;
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _assetService: AssetsService,
    private _location: Location,
    private _dialog: MatDialog,
    private _adminService: AdminService,
    private _datePipe: DatePipe
  ) { }

  get interval(): number {
    return this._interval;
  }
  set interval(interval: number) {
    if (this._interval !== interval) {
      this._interval = interval;
      this.intervalChanged = true;
    }
  }

  get MODEL_CHANGE(): boolean {
    return this._MODEL_CHANGE;
  }
  set MODEL_CHANGE(MODEL_CHANGE: boolean) {
    this._MODEL_CHANGE = MODEL_CHANGE;
  }

  get STATUS_CHANGE(): boolean {
    return this._STATUS_CHANGE;
  }
  set STATUS_CHANGE(STATUS_CHANGE: boolean) {
    this._STATUS_CHANGE = STATUS_CHANGE;
  }

  ngOnInit() {
    this._route.paramMap.pipe(
      map(params => {
        const id = params.get('id');
        this.assetId = id;
        return id;
      }), 
      mergeMap(id => {
        const getAssetDetails = this._assetService.getAssetDetails(id);
        const getAdminAssetInstallations = this._adminService.getAdminScoutInstallation(id);
        const getAssetModels = this._assetService.getAssetModels() as Observable<any>;
        const getAssetOwnership = this._adminService.getScoutAssetOwnershipHistory(id);
        const getAssetStatus = this._assetService.getAssetStatus();
        const getNotes = this._adminService.getNotesByEntityCodeOrIdSecondary(null, id);

        return forkJoin([
          getAssetDetails,
          getAdminAssetInstallations,
          getAssetModels,
          getAssetOwnership,
          //getAssetsScout,
          getAssetStatus,
          getNotes
        ]);
      })
    ).subscribe(results => {
      const asset = results[0];
      const installs = results[1];
      const models = results[2];
      const aso = results[3];
      const status = results[4];
      const notes = results[5];

      this.notes = notes;
      this.connectivityCodeFormControl.disable();
      this.asset = asset;
      this.assetModel = models;
      this.assetStatus = status;
      this.asset.assetModelId = this.findAssetModel(models);
      this.title = `${this.model.basicName}: ${asset.basicName}`;
      this.toolTip = 'Find ownership, installs, and details regarding this asset. If the asset type is a Scout then Interval will also be included. Click to refresh.';
      if (aso) {
        this.ownershipHistory = aso.reverse();
        this.formatASO();
      }

      if (installs.length > 0) {
        this.installs = installs;
        this.formatInstalls();
        this.scoutId = installs[0].scoutId;
        if (this.scoutId) {
          this._adminService.getScoutsByAssetIds([this.assetId]).subscribe(results => {
            if (results) {
              this.scouts = results;
              this.formatScoutContent();
              this.assignScoutName();
            }
          });
        }
        this.initInstalls = this.installs;
        for (let i = this.yearMin; i < this.year; i++) {
          this.years.push(i);
        }
        this.selectedYear = new Date().getFullYear();
        this.selectedBeginMonth = 'All';
        this.getInterval();
      }
      this.loading = false;
    },
      throwError,
      () => {
        console.log('test');
      });
  }


  ngOnDestroy() {
    if (this._dialog) {
      this._dialog.ngOnDestroy();
    }
    if (this.assetSubscription) {
      this.assetSubscription.unsubscribe();
    }
  }

  connectivityCodeChangeEvent() {
    const isEnabled: boolean = this.connectivityCodeChange;
    if (isEnabled) {
      this.connectivityCodeFormControl.enable();
    } else {
      this.connectivityCodeFormControl.disable();
    }
  }

  modelChanged(asset: any) {
    console.log(asset)
    this.MODEL_CHANGE = true;
    if (this.MODEL_CHANGE) {
      const dialogRef = this._dialog.open(ConfirmAssetModelDialogComponent, {
        width: "100%",
        data: asset,
      });
      dialogRef.componentInstance.event.subscribe(result => {
        console.log(result);
      });
    }
  }

  statusChanged(asset: any) {
    console.log(asset)
    this.STATUS_CHANGE = true;
    if (this.STATUS_CHANGE) {
      const dialogRef = this._dialog.open(ConfirmAssetModelDialogComponent, {
        width: "100%",
        data: asset,
      });
      dialogRef.componentInstance.event.subscribe(result => {
        console.log(result);
      });
    }
  }

  enableModelChange(): boolean {
    const dialogRef = this._dialog.open(ConfirmAssetModelDialogComponent, {
      width: "100%",
      data: this.asset,
    });
    dialogRef.componentInstance.event.subscribe(result => {
      console.log(result);
      this.MODEL_CHANGE = result;
      return this.MODEL_CHANGE
    });
    return this.MODEL_CHANGE
  }

  onNoClick() {
    this._router.navigate(['/assets']);
  }

  onSubmit(): void {
    this.event.emit({ init: this.assetInitState, change: this.asset });
  }

  navigateToCustomerDetails(id: string) {
    this._router.navigate([`/customers/${id}`]);
  }

  navigateMap(install: any) {
    this.coordinates = [install.coordinateLatitude, install.coordinateLongitude]
  }

  getAssetData(id: string) {
    this.loading = true;
    const getAssetDetails = this._assetService.getAssetDetails(id);
    const getAdminAssetInstallations = this._adminService.getAdminScoutInstallation(id)
      .pipe(catchError((err) => of(undefined)));
    const getAssetModels = this._assetService.getAssetModels()
      .pipe(catchError((err) => of(undefined)));
    const getNotes = this._adminService.getNotesByEntityCodeOrIdSecondary(null, id);

    return forkJoin([getAssetDetails, getAdminAssetInstallations, getAssetModels, getNotes])
      .subscribe(results => {
        const asset = results[0];
        const installs = results[1];
        const models = results[2];
        const notes = results[3];

        this.asset = asset;
        this.assetModel = models;
        this.notes = notes;
        this.asset.assetModelId = this.findAssetModel(models);
        if (installs.length > 0) {
          this.installs = installs;
          this.formatInstalls();
          this.scoutId = installs[0].scoutId;
          this.initInstalls = this.installs;
          for (let i = this.yearMin; i < this.year; i++) {
            this.years.push(i);
          }
          this.selectedYear = new Date().getFullYear();
          this.selectedBeginMonth = 'All';
          this.getInterval();
          this.assignScoutName();
        }
        this.loading = false;
        this.MODEL_CHANGE = false;
      },
        throwError,
        () => {
          console.log('test');
        });
  }

  noteAdded($event) {
    if ($event) {
      this._adminService.getNotesByEntityCodeOrIdSecondary(null, this.assetId).subscribe(result => {
        this.notes = result;
      })
    }
  }

  onUpdate(asset: Asset): void {
    this.loading = true;
    let patchAsset;
    if (!this.MODEL_CHANGE) {
      patchAsset = {
        basicDescription: asset.basicDescription,
        basicName: asset.basicName,
        assetStatuslId: asset.assetStatuslId
      };
    } else {
      patchAsset = {
        basicDescription: asset.basicDescription,
        basicName: asset.basicName,
        assetModelId: asset.assetModelId,
        assetStatuslId: asset.assetStatuslId
      };
    }
    if (this.connectivityCodeChange) {
      patchAsset.connectivityCode = null;
      patchAsset.connectivityCode = asset.connectivityCode;
    }
    this.assetSubscription = this._assetService.updateAsset(asset.id, patchAsset).subscribe(update => {
      if (this.updateScout.name != this.initScoutName) {
        this._adminService.postAdminScoutTable(this.updateScout, this.updateScout.id).subscribe();
      }

      this.getAssetData(this.assetId);
    });
  }

  getInterval() {
    this.assetSubscription = this._assetService.getScoutTable(this.scoutId)
      .subscribe(resp => {
        this.scout = resp;
        this._interval = this.parseContentField(resp);
      });
  }

  navigateBack() {
    this._location.back();
  }

  deleteDialog(asset: Asset): void {
    console.log(asset);
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: "60vw",
      data: `Delete ${asset.basicName}?`
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        console.log('Yes clicked');
        console.log(result);
        if (result) {
          // added table delete because full delete doesn't always work when defaults weren't setup
          this._assetService.tableDeleteAsset(asset.id).subscribe(d => {
            console.log(d);
            console.log('Table Delete used');
            this._router.navigate(['/assets']);
          });
        } else {
          this._router.navigate(['/assets']);
        }
      }
    });
  }

  parseContentField(scout: ScoutContent): number {
    if (scout.content !== null || scout.content !== undefined) {
      const parsed = scout.content.split(',');
      const converted = parseInt(parsed[0]);
      return converted;
    }
  }

  findAssetModel(models: AssetModel[]): string {
    this.model = models.find(a => a.id == this.asset.assetModelId);

    return this.model.id;
  }

  formatASO(): void {
    for (let i = 0; i < this.ownershipHistory.length; i++) {
      if (this.ownershipHistory[i].whenBegin) {
        this.ownershipHistory[i].whenBegin = "Begin: " + this._datePipe.transform(
          new Date(this.ownershipHistory[i].whenBegin), 'yyyy-MM-dd');
      }
      else {
        this.ownershipHistory[i].whenBegin = "Begin is null";
      }

      if (this.ownershipHistory[i].whenEnd) {
        this.ownershipHistory[i].whenEnd = "End: " + this._datePipe.transform(
          new Date(this.ownershipHistory[i].whenEnd), 'yyyy-MM-dd');
      }
      else {
        this.ownershipHistory[i].whenEnd = "";
      }
    }
  }

  formatScoutContent(): void {
    for (let i = 0; i < this.scouts.length; i++) {
      let index = this.scouts[i].content.lastIndexOf(",") + 1;
      let subString = this.scouts[i].content.substring(index);
      this.scouts[i].content = this._datePipe.transform(new Date(subString), 'yyyy-MM-dd');
      this.scouts[i].firmwareVersion = "Firmware Version: " + this.scouts[i].firmwareVersion;
    }
  }

  formatInstalls(): void {
    this.formattedInstalls = [];
    for (let i = 0; i < this.installs.length; i++) {
      let install: any = {};
      if (this.installs[i].whenBegin) {
        install.whenBegin = "Begin: " + this._datePipe.transform(new Date(this.installs[i].whenBegin), 'yyyy-MM-dd');
      }
      else {
        install.whenBegin = "Begin is null";
      }

      if (this.installs[i].whenEnd) {
        install.whenEnd = "End: " + this._datePipe.transform(new Date(this.installs[i].whenEnd), 'yyyy-MM-dd');
      }
      else {
        install.whenEnd = "End is null";
      }

      install.coordinateLatitude = "Latitude: " + this.installs[i].coordinateLatitude;
      install.coordinateLongitude = "Longitude: " + this.installs[i].coordinateLongitude;
      install.coordinateAltitude = "Altitude: " + this.installs[i].coordinateAltitude;

      this.formattedInstalls.push(install);
    }
  }

  assignScoutName(): void {
    let activeScoutId = this.installs.find(i => i.whenEnd == null).scoutId;
    this.updateScout = this.scouts.find(s => s.id == activeScoutId);
    this.initScoutName = this.updateScout.name;
  }
}