<h3 mat-dialog-title>
  Asset:
  {{data.asset.name}}
  <br>
  <small class="text small-text">
    Code: {{data.asset.code}} |
    Type: {{ data.asset.assetTypeName }} |
    Description: {{ data.asset.description }}
  </small>
  <br>
  <small class="text small-text">

  </small>
</h3>
<div mat-dialog-content cdkDrag cdkDragRootElement=".cdk-overlay-pane" cdkDragHandle>
  <!-- <form class="asset-form" (ngSubmit)="onSubmit($event)"> -->
  <mat-label *ngIf="!targetCustomer" disabled>Please select </mat-label>
  <mat-label *ngIf="targetCustomer" mat-dialog-title>Will be assigned to:
  </mat-label>
  <mat-form-field>
    <mat-select matInput placeholder="Customer" required [(ngModel)]="assetsToAssign" name="basicName"
      (selectionChange)="selectedCustomer($event)">
      <mat-option *ngFor="let available of customers | sort: 'basicName'" [value]="available">
        {{ available.name }}
      </mat-option>
    </mat-select>
  </mat-form-field>
  <br>
  <div *ngIf="targetCustomer && isScout">
    <mat-label mat-dialog-title>Scout created as:
    </mat-label>
    <input style="max-width: 70%" placeholder="{{name}}" matInput [(ngModel)]="name"
      [ngModelOptions]="{standalone: true}" />
    <hr />
    <mat-slide-toggle [(ngModel)]="doScoutSensor">
      Send Attached Assets ({{doScoutSensor}})
    </mat-slide-toggle>
  </div>
  <hr />
  <button mat-raised-button type="submit" [disabled]="!targetCustomer" color="primary" style="max-width: min-content"
    (click)="onSubmit($event)">
    Xfer
  </button>
  <!-- </form> -->
</div>
<div mat-dialog-actions>
  <button mat-raised-button class="close" (click)="onNoClick()" color="warn" style="max-width: min-content">
    Cancel
  </button>
</div>