<div class="col-12">
  <h3 class="text label-header-text" style="text-align: center;">Scout Interval</h3>
  <a mat-button (click)="navigateToScout(_scoutId)" style="position: absolute; right: 0; top: 0;">
    <mat-icon>edit</mat-icon>
    <!-- Scout details -->
  </a>
</div>
<div class="col-12 text label-text" style="text-align: center;">
  Scout interval set to:
  <b>
    <input
      style="max-width: 45px"
      type="number"
      matInput
      [(ngModel)]="counterValue"
    />
  </b>
  minutes (<b>{{ counterInHours }} hours</b>)
</div>
<div class="col-12">
  <div class="col-2" style="float: left; padding-left: 0px">
    <a
      mat-icon-button
      color="primary"
      (click)="update(scoutId)"
      style="float: left"
    >
      <mat-icon class="counter" [ngClass]="{ notify: status === 'true' }">
        alarm
      </mat-icon>
  <!-- <p style="text-align: center;">
  Set Interval
  </p> -->
    </a>
  </div>
  
  <div class="col-10" style="float: right; padding-left: 0px">
      <mat-slider
      thumbLabel
      tickInterval="1"
      min="1"
      max="1440"
      step="1"
      value="counterValue"
      type="range"
      [(ngModel)]="counterValue"
      style="width: -webkit-fill-available"
    ></mat-slider>
  </div>
</div>
<br>
<div class="col-12 text small-text" *ngIf="scout">
  <!-- <mat-checkbox *ngIf="scout" [(ngModel)]="scout.sendCommandOnEachRequest" [ngModelOptions]="{standalone: true}">Send Command on Each Request</mat-checkbox> -->
  <br>
  Send Command on Each  ({{scout.sendCommandOnEachRequest}})
  <br>
  <mat-slide-toggle style="float: left;"
      [(ngModel)]="scout.sendCommandOnEachRequest">
  </mat-slide-toggle>
  <a
      mat-raised-button
      color="primary"
      (click)="update(scoutId)"
      style="position: absolute; right: 0px; bottom: 20px;"
    >
    Set Interval/Send Command
  </a>
</div>
<mat-form-field>
  <mat-slide-toggle style="position: absolute; right: 0; z-index: 1000;"
      [(ngModel)]="sendCommand" name="sendCommand"
      (change)="sendCommandChangeEvent()">
  </mat-slide-toggle>
  <input class="text dashboard-text" matInput placeholder="Command to Send"
      [(ngModel)]="command" [formControl]="sendCommandFormControl" />
</mat-form-field>
<p class="small-text">
  {{'{'}}{{'{'}}SCT{{'}'}}{{'{'}}INT,60{{'}'}}{{'{'}}SEN,60{{'}'}}{{'}'}} for Scout 3.x.x 
  <br> 
  '\r''\n''\r''\n'{{'{'}}1,60{{'}'}}'\r''\n' for Scout 2.x.x
</p>
<mat-progress-bar
  style="position: static; z-index: 1; left: 0; right: 0; top: 50px; bottom: 0"
  *ngIf="loading"
  mode="query"
>
</mat-progress-bar>