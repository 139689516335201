import { tap, map, mergeMap } from "rxjs/operators";
import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  AfterViewInit,
  ViewChild,
  OnDestroy,
} from "@angular/core";
import { ActivatedRoute, Params, Router } from "@angular/router";
import { Customer } from "../../../shared/interfaces/customer";
import { AssetOwnership } from "../../../shared/interfaces/asset-ownership";
import { Observable, forkJoin, Subscription } from "rxjs";
import {
  User,
  ConstantService,
  ActionType,
  RootsActionMenuItem,
  RootsPageComponentConfig,
} from "../../../shared";
import { AdminService } from "../../../shared/services/admin.service";
import { MatDialog } from "@angular/material/dialog";
import { UsersUserGroupsComponent } from "./users-user-groups/users-user-groups.component";
import { AdminUser } from "../../../shared/interfaces/admin/admin-user";
import { SnackService } from "../../../shared/services/extra/snack.service";
import { UserCreateFormComponent } from "./create/user-create-form.component";
import { EmailDialogComponent } from "../../../shared/components/dialogs";
import { CustomerService } from "../../../shared/services/customer.service";
import { SearchBarService } from "../../../shared/services/searchbar.service";
import { MatTableDataSource } from "@angular/material/table";
import { ConfirmationDialogComponent } from "../../../shared/components/dialogs/confirmation-dialog/confirmation-dialog.component";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import {
  trigger,
  state,
  style,
  transition,
  animate,
} from "@angular/animations";
import { ShowGroupDialogComponent } from "./dialog/show-groups-dialog.component";

@Component({
  selector: "app-users",
  templateUrl: "./users.component.html",
  animations: [
    trigger("detailExpand", [
      state("collapsed", style({ height: "0px", minHeight: "0" })),
      state("expanded", style({ height: "*" })),
      transition(
        "expanded <=> collapsed",
        animate("225ms cubic-bezier(0.4, 0.0, 0.2, 1)")
      ),
    ]),
  ],
})
export class UsersComponent implements OnInit, AfterViewInit, OnDestroy {
  @Output() selectEmitt = new EventEmitter();
  @Input() customer: Customer;
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  assets?: AssetOwnership[];
  users = [];
  unfilteredUsers = [];
  customerId = "";
  userGroups: any[];
  expandedElement: any;
  isTableExpanded = false;
  _config: RootsPageComponentConfig = {
    loading: true,
    title: "",
    toolTip: "",
    dataSource: new MatTableDataSource<any>(),
    // userGroups: [{…}]
    displayedColumns: [
      // "code",
      "name",
      "authUsername",
      "userType",
      "description",
      "region",
    ],
    message: "",
  };
  actionButtons: RootsActionMenuItem[] = [
    {
      name: "Create User",
      icon: "add",
      toolTip: "Create a new User for this Customer",
      type: ActionType.Create,
    },
  ];
  items: RootsActionMenuItem[] = [
    {
      name: "Invite Email",
      toolTip: `Resend signup email Invite`,
      // `Resend signup to: ${ this.user.authUsername }`,
      icon: "email",
      type: 9,
    },
    {
      name: "Assign Group",
      toolTip: `Add this user to another Group`,
      icon: "group_add",
      type: 10,
    },
    {
      name: "Show Groups",
      toolTip: `Display groups on this user`,
      icon: "groups",
      type: 11,
    },
    {
      name: "Details",
      toolTip: `Navigate to user details page`,
      icon: "edit",
      type: 12,
    },
  ];
  searchSubscription: Subscription;
  componentSubscription: Subscription;
  constructor(
    private _route: ActivatedRoute,
    private _router: Router,
    private _customerService: CustomerService,
    private _adminService: AdminService,
    public _dialog: MatDialog,
    private _snack: SnackService,
    public searchBarService: SearchBarService
  ) {
    this._route.paramMap
      .pipe(
        map((params) => {
          const id = params.get("id");
          this.customerId = id;
          return id;
        }),
        mergeMap((id) => {
          const getCustomerDetails =
            this._customerService.getCustomerDetails(id);
          const getCustomersUsers = this._adminService.getPagedCustomersUsers(
            id,
            null,
            null
          ); //pagination
          // const getCustomersUsers = this._adminService.getCustomersUsers(id);
          const getCustomersUserGroups =
            this._adminService.getCustomersUserGroups(id);

          return forkJoin([
            getCustomerDetails,
            getCustomersUsers,
            getCustomersUserGroups,
          ]);
        })
      )
      .subscribe((results) => {
        const details = results[0];
        const customersUsers = results[1];
        const customerUGs = results[2];

        this.customer = details;
        this.users = customersUsers;
        this.unfilteredUsers = customersUsers;
        this.userGroups = customerUGs;
        this.users = this.pushRegion();
        this.addUserTypeToUsers();
        this._config.title = `Customer ${details.basicName}'s Users`;
        this._config.toolTip =
          "Find and manage details regarding a customers user's. Click to refresh.";
        this.addExpandable();
        this.pushConfig();
        this._config.dataSource.data = this.users as AdminUser[];
        console.log(this.users);
        this._config.loading = false;
      });
  }

  ngOnInit() {
    this._config.dataSource.sort = this.sort;
    this.searchSubscription = this.searchBarService.currentMessage.subscribe(
      (message) => {
        this._config.message = message;
        if (message.length > 0) {
          this.searchBarService.doFilter(message, this._config.dataSource);
        }
      }
    );
  }

  ngAfterViewInit() {
    this._config.dataSource.sort = this.sort;
    this._config.dataSource.paginator = this.paginator;
  }

  ngOnDestroy() {
    if (this.searchSubscription) {
      this.searchSubscription.unsubscribe();
    }
    if (this.componentSubscription) {
      this.componentSubscription.unsubscribe();
    }
    if (this._dialog) {
      this._dialog.ngOnDestroy();
    }
  }

  pushRegion(): any[] {
    let populated = [];
    for (let i = 0; i < this.users.length; i++) {
      const user = this.users[i];
      const region = localStorage.getItem(user.regionId);
      if (region) {
        user.region = region;
        populated.push(user);
      }
    }
    return populated;
  }

  pushConfig() {
    this.items.push({
      name: "Delete",
      toolTip: `Delete this user on for ${this.customer.basicName}`,
      icon: "delete",
      type: 13,
    });
  }

  actionsClicked(action) {
    const type = action[0];
    const item = action[1];
    switch (type.type) {
      case 9:
        this.emailDialog(item);
        break;
      case 10:
        //assign group
        this.groupsDialog(item);
        break;
      case 11:
        this.showDialog(item);
        break;
      case 12:
        this.navigateToUser(item);
        break;
      case 13:
        this.deleteDialog(item);
        break;
      default:
        break;
    }
  }

  getUsersCustomers() {
    this.componentSubscription = this._adminService
      .getCustomersUsers(this.customerId)
      .subscribe((u) => {
        this.users = u;
      });
  }

  getUserTypeFromId(id: string) {
    switch (id) {
      case "022a7209-57c0-4643-8172-627c087728b1":
        return "SME";
      case "5b780234-5ea7-449d-acee-698faa0c0a11":
        return "Super";
      case "7c26a47f-ccac-4e5e-ad00-e63301fd0655":
        return "Admin";
      case "dd6944a8-f1e8-43ae-80ab-11d29a4285bf":
        return "User";
      case "fe7df56e-0577-4867-899b-7320df998186":
        return "Advanced";
      case "2419D037-2F66-47D3-A1BD-B68BA959F812":
        return "Dev Admin";
      case "2C73C12A-A769-4279-B060-2865D9E6C1F3":
        return "Production Admin";
      case "DA150D8D-E7AC-4E91-BB0B-C83ADA018BFD":
        return "Support Admin";
      case "D2A2E8EB-F80B-41A4-A1EC-2DF4071A1D73":
        return "[DEMO]";
      case "E94C84B6-5326-4352-AAC4-C845A6C8D370":
        return "[INACTIVE]";
      case "F76F8460-A381-4E3D-A385-84BF6CB968C4":
        return "[DELETED]";
      default:
        return "";
    }
  }

  addUserTypeToUsers() {
    for (let i = 0; i < this.users.length; i++) {
      this.users[i]['userType'] = this.getUserTypeFromId(this.users[i].userTypeId);
    }
  }

  refresh() {
    this._config.loading = true;
    const getCustomerDetails = this._customerService.getCustomerDetails(
      this.customerId
    );
    const getCustomersUsers = this._adminService.getPagedCustomersUsers(
      this.customerId,
      null,
      null
    ); //pagination
    // const getCustomersUsers = this._adminService.getCustomersUsers(id);
    const getCustomersUserGroups = this._adminService.getCustomersUserGroups(
      this.customerId
    );

    forkJoin([
      getCustomerDetails,
      getCustomersUsers,
      getCustomersUserGroups,
    ]).subscribe((results) => {
      const details = results[0];
      const customersUsers = results[1];
      const customerUGs = results[2];

      this.customer = details;
      this.users = customersUsers;
      this.addUserTypeToUsers();
      this.unfilteredUsers = customersUsers;
      this.userGroups = customerUGs;
      this._config.dataSource.data = this.users as AdminUser[];
      this._config.loading = false;
    });
  }

  addExpandable() {
    for (let i = 0; i < this.users.length; i++) {
      const user = this.users[i];
      user.isExpanded = false;
    }
  }

  showDialog(data): void {
    const dialogRef = this._dialog.open(ShowGroupDialogComponent, {
      width: "75vw",
      height: "85vh",
      data: data,
    });
    dialogRef.componentInstance.event.subscribe((result) => {
      if (result.data) {
      }
    });
  }

  openDialog(): void {
    alert("Email Must be unique!");
    const dialogRef = this._dialog.open(UserCreateFormComponent, {
      width: "75vw",
      height: "85vh",
      data: {
        dataKey: this.customer,
        id: this.customerId,
        title: "Create User",
        count: this.users.length,
      },
    });
    dialogRef.componentInstance.event.subscribe((result) => {
      if (result.data) {
        this.refresh();
      }
    });
  }

  //assign groups dialog
  groupsDialog(user: AdminUser): void {
    const dialogRef = this._dialog.open(UsersUserGroupsComponent, {
      width: "75vw",
      height: "85vh",
      data: {
        groups: user.userGroups,
        customerId: this.customerId,
        user: user,
        title: "Quick Edit on User Groups",
        customerGroups: this.userGroups,
      },
    });
    dialogRef.componentInstance.event.subscribe((result) => {
      this.refresh();
    });
  }

  //this is where email dialog
  emailDialog(user: any): void {
    const dialogRef = this._dialog.open(EmailDialogComponent, {
      width: "80vw",
      height: "90vh",
      data: {
        to: user.authUsername,
        from: "support@earthscout.com",
        name: user.name
      },
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result == 202) {
        setTimeout(() => {
          this._snack.displaySuccess("Email Sent");
        }, 1000);
      }
    });
  }

  topActionsClicked(action) {
    switch (action.type) {
      case 0:
        this.openDialog();
        break;
      default:
        break;
    }
  }

  onNoClick() {
    this._router.navigate(["/customers"]);
  }

  toggleTableRows() {
    this.isTableExpanded = !this.isTableExpanded;
    for (let i = 0; i < this._config.dataSource.data.length; i++) {
      const row = this._config.dataSource.data[i];
      row.isExpanded = this.isTableExpanded;
    }
  }

  deleteDialog(user: any): void {
    console.log(user);
    const dialogRef = this._dialog.open(ConfirmationDialogComponent, {
      width: "60vw",
      data: `Delete ${user.name}?`,
    });
    dialogRef.afterClosed().subscribe((result) => {
      if (result) {
        this.componentSubscription = this._customerService
          .deleteUser(user.id)
          .subscribe((d) => {
            this.refresh();
          });
      }
    });
  }

  noUserGroups(user: any): boolean {
    if (user.userGroups.length < 1) {
      return true;
    } else {
      return false;
    }
  }

  pullGroupInfo(group: any) {
    this._config.loading = true;
    if (group.details) {
      this._router.navigate([
        `/customers/${group.details.customerId}/usergroups/${group.id}`,
      ]);
    } else {
      this.componentSubscription = this._customerService
        .getUserGroupDetails(group.id)
        .subscribe((results) => {
          group.details = results;
          for (let i = 0; i < this.userGroups.length; i++) {
            const groups = this.userGroups[i];
            if (group.id === groups.id) {
              group.currentCustomer = true;
              this._config.loading = false;
            } else {
              group.currentCustomer = false;
              this._config.loading = false;
            }
          }
        });
    }
  }

  navigateToUser(user: User) {
    const queryParams: Params = { username: user.authUsername };
    this._router.navigate([`/customers/${this.customerId}/users/${user.id}`], {
      queryParams: queryParams,
    });
  }
}
