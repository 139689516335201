<div class="margin-left">
  <mat-label>Select Utility</mat-label>
  <mat-form-field class="mat-form-field col-4">
    <mat-select (selectionChange)="chooseUtility($event)">
      <mat-option>
        <ngx-mat-select-search placeholderLabel="Search for a Utility" [formControl]="utilityFilterCtrl"
          noEntriesFoundLabel="'no matching utility found'"></ngx-mat-select-search>
      </mat-option>
      <mat-option *ngFor="let utility of filteredUtilities" value="{{utility.id}}">{{utility.name}}</mat-option>
    </mat-select>
  </mat-form-field>
</div>

<div *ngIf="selectedUtility != null">
  <mat-card>
    <h1>{{selectedUtility.name}}</h1>
    <p>{{selectedUtility.description}}</p>

    <form [formGroup]="parameterFormGroup" (ngSubmit)="generateReport()">
      <div *ngFor="let param of selectedUtility.publicInfo.request.parameters; index as i">

        <mat-form-field *ngIf="param.typeName == 'datetimeoffset'" class="form-field col-3">
          <mat-label>{{param.name}}{{selectedUtility.publicInfo.request.parameters[i].isOptional ? " (optional)" : "" }}</mat-label>
          <input matInput [matDatepicker]="picker" formControlName="{{param.code}}"
            [required]="!selectedUtility.publicInfo.request.parameters[i].isOptional"
            (dateChange)="dateInputChange($event.targetElement.value, param.code)">
          <mat-hint>MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-datepicker #picker></mat-datepicker>
          <mat-error>{{dateTimeErrorMessage(param.code, i)}}</mat-error>
        </mat-form-field>


        <!-- <mat-form-field *ngIf="param.type == 'datetimeoffset'" class="form-field col-6">
          <mat-label>{{param.name}}</mat-label>
          <mat-date-range-input [formControlName]="param.code" [rangePicker]="picker" [required]="param.isRequired">
            <input matStartDate [(ngModel)]="param.startDate" placeholder="Start">
            <input matEndDate [(ngModel)]="param.endDate" placeholder="End" (dateChange)="changeDate($event)">
          </mat-date-range-input>
          <mat-hint>MM/DD/YYYY - MM/DD/YYYY</mat-hint>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field> -->

        <mat-form-field *ngIf="param.typeName == 'string'" class="form-field col-6">
          <mat-label>{{param.name}}{{selectedUtility.publicInfo.request.parameters[i].isOptional ? " (optional)" : "" }}</mat-label>
          <input matInput type="text" (input)="stringInputChange($event.target.value, param.code)"
            [required]="!selectedUtility.publicInfo.request.parameters[i].isOptional"
            formControlName="{{param.code}}" />
          <mat-error>{{stringErrorMessage(param.code, i)}}</mat-error>
        </mat-form-field>
        <mat-form-field *ngIf="param.typeName == 'int'" class="form-field col-3">
          <mat-label>{{param.name}}{{selectedUtility.publicInfo.request.parameters[i].isOptional ? " (optional)" : "" }}</mat-label>
          <input matInput type="number" (input)="numberInputChange($event.target, param.code)"
            [required]="!selectedUtility.publicInfo.request.parameters[i].isOptional"
            formControlName="{{param.code}}" />
          <mat-error>{{numberErrorMessage(param.code, i)}}</mat-error>
        </mat-form-field>
      </div>

      <button mat-flat-button color="primary" [disabled]="!parameterFormGroup.valid">
        Generate Report
      </button>
    </form>
    <div *ngIf="reportGenerated">
    <!-- <div [hidden]="!reportGenerated"> -->
      <mat-tab-group class="margin-top margin-right">
        <mat-tab *ngFor="let dataset of selectedUtility.publicInfo.result.datasets; let i = index"
          label="{{dataset.name}}">
          <button mat-flat-button color="primary" (click)="hideTable = !hideTable" class="margin-top">
            {{hideTable ? "Show Table" : "Hide Table"}}
          </button>
          <div class="margin-bottom">
            <mat-table matTableExporter [dataSource]="dataSources[i]" #exporter="matTableExporter" [hidden]="hideTable">
              <div *ngFor="let column of displayedColumns[i]; let j = index">
                <ng-container matColumnDef="{{displayedColumns[i][j]}}">
                  <mat-header-cell
                    *matHeaderCellDef>{{selectedUtility.publicInfo.result.datasets[i].attributes[j].name}}</mat-header-cell>
                  <mat-cell *matCellDef="let data">{{data[column]}}</mat-cell>
                </ng-container>
              </div>

              <mat-header-row *matHeaderRowDef="displayedColumns[i]"></mat-header-row>
              <mat-row *matRowDef="let row; columns: displayedColumns[i];"></mat-row>
            </mat-table>
            <mat-paginator [hidden]="hideTable" [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
          </div>

          <mat-form-field class="mat-form-field col-4">
            <mat-label>Select a file name</mat-label>
            <input matInput [(ngModel)]="fileNames[i]" />
          </mat-form-field>
          <button mat-raised-button color="primary"
            (click)="exporter.exportTable('xlsx', {fileName:fileNames[i], sheet: 'Report'})">
            Export Data to Excel File
          </button>
        </mat-tab>
      </mat-tab-group>
    </div>
  </mat-card>
</div>