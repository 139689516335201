<roots-page-header
  [toolTip]="toolTip"
  [title]="title"
  [loading]="loading"
  (refresh)="getCustomerData(customerId)"
>
</roots-page-header>
<div class="col-12">
  <div class="col-12 col-sm-12 col-md-12 col-lg-6 col-xxl-6" style="float: left; box-sizing: border-box;">
    <mat-card *ngIf="customer">
      <h3 class="text label-header-text">Details</h3>
      <hr />
      <!-- <mat-form-field>
              <mat-label>Code</mat-label>
              <input matInput placeholder="Code" [(ngModel)]="customer.code" required>
          </mat-form-field> -->
          <!--max-height: 50vh; -->
      <div style="overflow: auto">
        <mat-form-field>
          <mat-label>Name</mat-label>
          <input
            matInput
            placeholder="Name"
            [(ngModel)]="customer.basicName"
            required
          />
        </mat-form-field>
      
        <mat-form-field>
          <mat-label>Description</mat-label>
          <input
            matInput
            placeholder="Description"
            [(ngModel)]="customer.basicDescription"
            required
          />
        </mat-form-field>
      
        <mat-form-field>
          <mat-select
            matInput
            placeholder="Region"
            required
            [(ngModel)]="customer.regionId"
            name="region"
          >
            <mat-option
              *ngFor="let reg of regions | sort: 'key'"
              [value]="reg.value"
            >
              {{ reg.key }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <mat-form-field>
          <mat-select
            matInput
            placeholder="Language"
            required
            [(ngModel)]="customer.languageTag"
            name="languageTag"
          >
            <mat-option
              *ngFor="let lang of languages | sort: 'key'"
              [value]="lang.value"
            >
              {{ lang.key }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      
        <mat-form-field>
          <mat-label>Contact Name</mat-label>
          <input
            matInput
            placeholder="Contact Name"
            [(ngModel)]="customer.contact_Name"
          />
        </mat-form-field>
      
        <mat-form-field>
          <mat-label>Contact Address</mat-label>
          <input
            matInput
            placeholder="Contact Address"
            [(ngModel)]="customer.contact_Address"
            required
          />
        </mat-form-field>
      
        <mat-form-field>
          <mat-label>Contact Telephone</mat-label>
          <input
            matInput
            placeholder="Contact Telephone"
            [(ngModel)]="customer.contact_Telephone"
            required
          />
        </mat-form-field>
      
        <mat-form-field>
          <mat-select
            matInput
            placeholder="Customer Type"
            required
            [(ngModel)]="customer.customerTypeId"
            name="customerType"
          >
            <mat-option *ngFor="let cat of types | sort: 'key'" [value]="cat.value">
              {{ cat.key }}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <button
        mat-raised-button
        type="submit"
        (click)="onUpdate(customer)"
        style="max-width: fit-content"
        color="primary"
      >
        Update
      </button>
      <!-- <button mat-raised-button class="close" (click)="onNoClick()" color="warn">Cancel</button> <br /> -->
      <button
        *ngIf="!hasAssets"
        mat-raised-button
        type="submit"
        (click)="deleteDialog(customer)"
        color="warn"
        style="width: fit-content"
      >
        Delete
      </button>
    </mat-card>
  </div>
  <app-notes *ngIf="customer" [entityId]="customer.id" [entityCode]="customer.code" [entityType]="'Customer'" [notes]="notes" (noteAdded)="noteAdded($event)">
  </app-notes>
  <app-roots-details-data-list 
        [data]="adminAssetList" 
        [prop_1]="'name'"
        [prop_2]="'assetTypeName'"
        [prop_4]="'description'"
        [prop_5]="'asoWhenBegin'"
        [prop_6]="'code'"
        [type]="0"
        [icon]="'widgets'"
        [title]="'Current Assets'">
  </app-roots-details-data-list>
  <!-- [prop_3]="'userAssetName'" -->

  <!-- <app-roots-details-data-list 
        [data]="scouts" 
        [prop_1]="'name'"
        [prop_2]="'content'"
        [prop_3]="'description'"
        [prop_4]="'firmwareVersion'"
        [prop_5]="'code'"
        [type]="1"
        [icon]="'wifi_tethering'"
        [title]="'Scout Configurations'">
  </app-roots-details-data-list> -->
  <app-roots-details-data-list 
        [data]="users" 
        [prop_1]="'name'"
        [prop_2]="'authUsername'"
        [prop_3]="'description'"
        [type]="2"
        [icon]="'person'"
        [title]="'Current Users'">
  </app-roots-details-data-list>
  <!-- [prop_4]="'languageTag'" -->
  <!-- <app-roots-detail-page
        [header]="'Customer Details'"
        [fields]="fields">
  </app-roots-detail-page> -->
</div>
